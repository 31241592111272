@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";
@import "~@ng-select/ng-select/themes/material.theme.css";

.main-header {
  background-image: linear-gradient(to right,#50bebe,#099 48%,#0099b0 92%,#0099cb 100%);
  color: white;
}

.username {
  margin-left: 10px;
}
